.custom-tooltip {
  --bs-tooltip-bg: var(--bs-gray-800);
  --bs-tooltip-opacity: 1;
  // max-width: var(--bs-tooltip-max-width);
  --bs-tooltip-max-width: unset;

  .tooltip-inner {
    padding: 10px 10px;

    .custom-button-group {

      .btn {
        font-size: inherit;
      }
    }
  }
}

// REDUCED FORM-FLOATING ELEMENT HEIGHT BY 33%, ADJUSTED LABEL PADDING, AND FOCUS STATES

.form-floating > .form-control, 
.form-floating > .form-control-plaintext, 
.form-floating > .form-select {
  height: calc(2.4rem + calc(var(--bs-border-width) * 2));
  min-height: unset;
  // line-height: normal;
}

.form-floating {
  user-select: none;
  label {
    padding: 0.70rem 0.75rem !important;

    &::after {
      background-color: transparent !important;
      user-select: none;
    }
  }
}

.form-floating > .form-control-plaintext:focus, 
.form-floating > .form-control-plaintext:not(:placeholder-shown), 
.form-floating > .form-control:focus, 
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.725rem;
}

.form-floating > .form-control:focus {
  padding-top: 1.625rem;
  padding-bottom: 0.725rem;
  border-color: var(--bs-gray-400);
}

.cursor-pointer {
  cursor: pointer !important;
}

// BOOTSTRAP GRAY UTILITY CLASSES - TEXT COLOR
.text-gray-100 {
  color: var(--bs-gray-100) !important;
}

.text-gray-200 {
  color: var(--bs-gray-200) !important;
}

.text-gray-300 {
  color: var(--bs-gray-300) !important;
}

.text-gray-400 {
  color: var(--bs-gray-400) !important;
}

.text-gray-500 {
  color: var(--bs-gray-500) !important;
}

.text-gray-600 {
  color: var(--bs-gray-600) !important;
}

.text-gray-700 {
  color: var(--bs-gray-700) !important;
}

.text-gray-800 {
  color: var(--bs-gray-800) !important;
}

.text-gray-900 {
  color: var(--bs-gray-900) !important;
}

// BOOTSTRAP GRAY UTILITY CLASSES - BACKGROUND COLOR
.bg-gray-100 {
  background-color: var(--bs-gray-100) !important;
}

.bg-gray-200 {
  background-color: var(--bs-gray-200) !important;
}

.bg-gray-300 {
  background-color: var(--bs-gray-300) !important;
}

.bg-gray-400 {
  background-color: var(--bs-gray-400) !important;
}

.bg-gray-500 {
  background-color: var(--bs-gray-500) !important;
}

.bg-gray-600 {
  background-color: var(--bs-gray-600) !important;
}

.bg-gray-700 {
  background-color: var(--bs-gray-700) !important;
}

.bg-gray-800 {
  background-color: var(--bs-gray-800) !important;
}

.bg-gray-900 {
  background-color: var(--bs-gray-900) !important;
}
