// ROBOTO
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
// OPEN SANS
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
// LATO
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

// GLOBAL VARIABLES
$PageMaxWidth: 1500px;
$PageHeaderHeight_initial: 70px;
$PageHeaderHeight_onScroll: 50px;

// COLORS
$colors: (
	'light': #fafafa,
);
// COLOR UTILITY CLASSES
@each $key, $color in $colors {
	.text-#{$key} {
		color: $color;
	}
}

// HTML BASE FONT SIZE
html {
	font-size: 16px;
	font-family: 'Roboto';

	// @media (min-width: 500px) {
	// 	font-size: clamp(16px, 1.5vw + 1rem, 20px);
	// }

    @media (min-width: 600px) {
        font-size: calc(15px + 0.03vw);
    }
}

// FONT SIZES
$font-sizes: (
    'giant': clamp(3.5rem, 3vw + 2.5rem, 5rem),
    'xxxxl': clamp(3.2rem, 2.8vw + 2rem, 4rem),
    'xxxl': clamp(2.8rem, 2.6vw + 1.8rem, 3.5rem),
    'xxl': clamp(2.4rem, 2.3vw + 1.5rem, 3rem),
    'xl': clamp(2.1rem, 2vw + 1.3rem, 2.6rem),
    'lg': clamp(1.8rem, 1.8vw + 1.1rem, 2.2rem),
    'mdlg': clamp(1.6rem, 1.6vw + 0.95rem, 1.9rem),
    'md': clamp(1.3rem, 1.4vw + 0.8rem, 1.6rem),
    'mdsm': clamp(1.1rem, 1.25vw + 0.65rem, 1.35rem),
    'sm': clamp(0.9rem, 1.1vw + 0.5rem, 1rem),
    'xs': clamp(0.75rem, 1vw + 0.4rem, 0.85rem),
    'xxs': clamp(0.65rem, 0.9vw + 0.35rem, 0.75rem),
    'xxxs': clamp(0.5rem, 0.8vw + 0.3rem, 0.6rem),
    'xxxxs': clamp(0.4rem, 0.7vw + 0.25rem, 0.5rem),
    'tiny': clamp(0.3rem, 0.6vw + 0.2rem, 0.4rem)
);
// // SCSS FUNCTION TO RETURN FONT SIZE
// @function size($key) {
// 	@return map-get($font-sizes, $key);
// }
// FONT SIZE UTILITY CLASSES
@each $size, $value in $font-sizes {
	.fs-#{$size} {
		font-size: $value !important;
	}
}

