// .json-view {
// 	display: block;
// 	--json-property: #009033;
// 	--json-index: #676dff;
// 	--json-number: #676dff;
// 	--json-string: #b2762e;
// 	--json-boolean: #dc155e;
// 	--json-null: #dc155e;
// }
// .json-view .json-view--property { color: var(--json-property); }
// .json-view .json-view--index { color: var(--json-index); }
// .json-view .json-view--number {	color: var(--json-number); }
// .json-view .json-view--string {	color: var(--json-string); }
// .json-view .json-view--boolean { color: var(--json-boolean); }
// .json-view .json-view--null { color: var(--json-null); }

// .json-view .json-view--copy,
// .json-view .json-view--edit {
// 	display: none;
// 	width: 1em;
// 	height: 1em;
// 	margin-left: 4px;
// 	cursor: pointer;
// }
// .json-view .json-view--deleting {
// 	outline: 1px solid #da0000;
// 	background-color: #da000011;
// 	text-decoration-line: line-through;
// }
// .json-view .json-view--input {
// 	width: 120px;
// 	margin-left: 4px;
// 	border-radius: 4px;
// 	border: 1px solid currentColor;
// 	padding: 0px 4px;
// 	font-size: 87.5%;
// 	line-height: 1.25;
// 	background: transparent;
// }

// .json-view:hover > .json-view--copy,
// .json-view:hover > .json-view--edit,
// .json-view .json-view--pair:hover > .json-view--copy,
// .json-view .json-view--pair:hover > .json-view--edit {
// 	display: inline-block;
// }

// .json-view .jv-indent { padding-left: 16px; }
// .json-view .jv-chevron {
// 	display: inline-block;
// 	vertical-align: -20%;
// 	cursor: pointer;
// 	opacity: 0.4;
// 	width: 1em;
// 	height: 1em;
// }
// .json-view .jv-chevron:hover { opacity: 0.8;}
// .json-view .jv-button {
// 	background: transparent;
// 	outline: none;
// 	border: none;
// 	cursor: pointer;
// }
// .json-view .cursor-pointer { cursor: pointer; }

.json-view {
  position: relative;
  padding-left: 20px;

  .jv-size-chevron {
    position: absolute;
    left: 0;
  }

  .json-view--copy,
  .json-view--edit {
    width: 1.2em !important;
    height: auto;
  }
}
