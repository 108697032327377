/* 1. Universal Box Sizing */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* 2. Reset Margins */
* {
	margin: 0;
}

/* DISABLE SCROLLBAR FOR SPECIFIC ELEMENTS */
.no-scrollbar {
	overflow: hidden;
}

/* HIDE SCROLLBAR WHILE STILL ALLOWING SCROLLING (IF DESIRED) */
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}

/* 3. Base Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* 4. Typographic Adjustments */
body {
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
}

/* 5. Media Defaults */
img,
picture,
video,
canvas,
svg {
	display: block;
	max-width: 100%;
}

/* 6. Form Element Font Inheritance */
input,
button,
textarea,
select {
	font: inherit;
}

/* 7. Text Overflow Prevention */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}

/* 8. Root Stacking Context */
#root,
#__next {
	isolation: isolate;
}

a {
	text-decoration: unset !important;
}

/* Reset for input-related elements only */
input,
textarea,
select,
button,
input:before,
input:after,
textarea:before,
textarea:after,
select:before,
select:after,
button:before,
button:after {
	background: unset;
	outline: none !important;
	box-shadow: none !important;
	border: unset;
}

p {
	margin: 0;
}
