// Define an array of sizes
$sizes: (6, 8, 16, 32, 64, 10, 20, 30, 40, 50, 60, 80, 120);

// Utility generator function with !important
@mixin generate-utilities($property, $class-prefix) {
	@each $size in $sizes {
		.#{$class-prefix}-#{$size} {
			#{$property}: #{$size}px !important;
		}
		.#{$class-prefix}t-#{$size} {
			#{$property}-top: #{$size}px !important;
		}
		.#{$class-prefix}r-#{$size} {
			#{$property}-right: #{$size}px !important;
		}
		.#{$class-prefix}b-#{$size} {
			#{$property}-bottom: #{$size}px !important;
		}
		.#{$class-prefix}l-#{$size} {
			#{$property}-left: #{$size}px !important;
		}
		.#{$class-prefix}x-#{$size} {
			#{$property}-left: #{$size}px !important;
			#{$property}-right: #{$size}px !important;
		}
		.#{$class-prefix}y-#{$size} {
			#{$property}-top: #{$size}px !important;
			#{$property}-bottom: #{$size}px !important;
		}
	}
}

// Generate padding utilities
@include generate-utilities(padding, p);

// Generate margin utilities
@include generate-utilities(margin, m);

// Define the maximum size for gap
$max-gap: 30; // This defines up to `gap-30`

// Utility generator function for gap with steps of 2px
@mixin generate-gap-utilities($class-prefix, $step: 2, $max-size: $max-gap) {
	$size: 6;
	@while $size <= $max-size {
		.#{$class-prefix}-#{$size} {
			gap: #{$size}px !important;
			flex-gap: #{$size}px !important; // Use this if you need to support old syntax
		}
		$size: $size + $step;
	}
}

// Generate gap utilities
@include generate-gap-utilities('gap');

// Utility generator function for Flexbox alignment
@mixin generate-flex-alignment-utilities {
	// Align-items classes
	.a-center {
		align-items: center !important;
	}
	.a-start {
		align-items: flex-start !important;
	}
	.a-end {
		align-items: flex-end !important;
	}
	.a-baseline {
		align-items: baseline !important;
	}
	.a-stretch {
		align-items: stretch !important;
	}

	// Justify-content classes
	.j-center {
		justify-content: center !important;
	}
	.j-start {
		justify-content: flex-start !important;
	}
	.j-end {
		justify-content: flex-end !important;
	}
	.j-between {
		justify-content: space-between !important;
	}
	.j-around {
		justify-content: space-around !important;
	}
	.j-evenly {
		justify-content: space-evenly !important;
	}
}

// Generate Flexbox utilities
@include generate-flex-alignment-utilities;

// Define an array of sizes for border-radius
$border-radius-sizes: (2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32);

// Utility generator function for border-radius
@mixin generate-border-radius-utilities($class-prefix) {
	@each $size in $border-radius-sizes {
		.#{$class-prefix}-#{$size} {
			border-radius: #{$size}px !important;
		}
	}
	// Additional class for fully rounded corners
	.#{$class-prefix}-round {
		border-radius: 300px !important;
	}
}

// Generate border-radius utilities
@include generate-border-radius-utilities('br');

// Define a map of max-height values in pixels
$max-height-values: (
	2: 2px,
	4: 4px,
	6: 6px,
	8: 8px,
	10: 10px,
	12: 12px,
	14: 14px,
	16: 16px,
	20: 20px,
	24: 24px,
	28: 28px,
	32: 32px,
	40: 40px,
	48: 48px,
	56: 56px,
	64: 64px,
	72: 72px,
	80: 80px,
	100: 100px,
	120: 120px,
	150: 150px,
	200: 200px,
	full: 100% // Full height remains as a percentage,,,,,,,
);

// Generate max-height utility classes
@each $key, $value in $max-height-values {
	.h-#{$key} {
		height: $value !important;
	}
	.mh-#{$key} {
		max-height: $value !important;
	}
}

// Define an array of font weights
$font-weights: (100, 200, 300, 400, 500, 600, 700, 800, 900);

// Utility generator function for font weights
@mixin generate-font-weight-utilities($class-prefix) {
	@each $weight in $font-weights {
		.#{$class-prefix}-#{$weight} {
			font-weight: #{$weight} !important;
		}
	}
}

// Generate font weight utilities
@include generate-font-weight-utilities('fw');
