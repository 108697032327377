@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.App {
	font-family: 'Roboto';

	p {
		color: #333;
		opacity: 0.9;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
	font-family: 'Inter';
}
