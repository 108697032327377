// ABSOLUTE POSITIONING
$absolute-positioning-values: 0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50;
@each $value in $absolute-positioning-values {
	// POSITIVE VALUES
	.top-#{$value} {
		top: #{$value}px;
	}
	.bottom-#{$value} {
		bottom: #{$value}px;
	}
	.left-#{$value} {
		left: #{$value}px;
	}
	.right-#{$value} {
		right: #{$value}px;
	}

	// NEGATIVE VALUES
	.top--#{$value} {
		top: -#{$value}px;
	}
	.bottom--#{$value} {
		bottom: -#{$value}px;
	}
	.left--#{$value} {
		left: -#{$value}px;
	}
	.right--#{$value} {
		right: -#{$value}px;
	}
}

// WIDTH & HEIGHT
$width-and-height-values: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 18, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 110;
@each $value in $width-and-height-values {
	// WIDTH ONLY
	.w-#{$value} {
		width: #{$value}px !important;
	}

	// HEIGHT ONLY
	.h-#{$value} {
		height: #{$value}px !important;
	}

	// WIDTH AND HEIGHT
	.wh-#{$value} {
		width: #{$value}px !important;
		height: #{$value}px !important;
	}
}

// UNIVERSAL GAP
$gap-values: 0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 50;
$gap-values-rem: ( 1: 0.25rem, 2: 0.5rem, 3: 0.75rem, 4: 1.25rem, 5: 2.50rem );
@each $value in $gap-values {
	@if map-has-key($gap-values-rem, $value) {
		$gap-values: map-get($gap-values-rem, $value);
	} @else {
		$gap-values: #{$value}px;
	}

	// COLUMN GAP
	.ugx-#{$value} {
		row-gap: #{$gap-values};
	}

	// ROW GAP
	.ugy-#{$value} {
		column-gap: #{$gap-values};
	}

	// GAP
	.ug-#{$value} {
		gap: #{$gap-values};
	}
}

// BORDER RADIUS
// BOOSTRAP PROVIDES: rounded, rounded-[sm, lg, pill, circle, 0]
.round {
	border-radius: 4px;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.text-shadow-dark {
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.text-shadow-light {
	text-shadow: 4px -2px 5px rgba(81, 81, 81, 0.15), -2px 3px 11px rgba(255, 255, 255, 0.37);
}

.svg-shadow-dark {
	filter: drop-shadow(4px -2px 5px rgba(81, 81, 81, 0.25)) drop-shadow(-2px 3px 11px rgba(255, 255, 255, 0.5));
}

.hide-scrollbars {
	-ms-overflow-style: none !important;
	scrollbar-width: none !important;

	&::-webkit-scrollbar {
		display: none !important;
	}
}

// .hide-scrollbars::-webkit-scrollbar {
// 	display: none !important;
// }

.text-lighter {
	color: #f5f5f5;
}

.fit-content {
	width: fit-content !important;
}
