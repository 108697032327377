.sidebar {
	position: sticky !important;
	top: 0;
	max-height: 100vh;
	// width: 200px;
	width: auto;
	padding: 10px 0;
	box-shadow: 0 5px 5px -3px rgb(0 0 0 / 16%), 0 8px 10px 1px rgb(0 0 0 / 11%), 0 3px 14px 2px rgb(0 0 0 / 10%);
	transition: width 0.3s ease, padding 0.3s ease;

	.logo {
		max-width: 60px;
	}

	.sidebar-icon {
		min-width: 20px;
	}

	// .sidebar-menu {
	// 	width: 100%;

	// 	.link {
	// 		padding: 8px;
	// 		border-radius: 12px;
	// 		transition: all 0.3s ease-in-out;

	// 		.item {
	// 			opacity: 0.5;

	// 			&:hover {
	// 				opacity: 0.95;
	// 			}
	// 		}

	// 		&.selected {
	// 			width: 100%;
	// 			background-color: #b063c5;

	// 			.item {
	// 				opacity: 1;
	// 			}
	// 		}
	// 	}
	// }

	.sidebar-menu {
		width: 100%;
	
		.link {
			position: relative;
			padding: 8px;
			border-radius: 12px;
			overflow: hidden;
			transition: all 0.3s ease-in-out;
			display: flex;
			align-items: center;
			justify-content: flex-start;
	
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: -100%;
				width: 100%;
				height: 100%;
				background-color: #b063c5;
				transition: left 0.4s ease;
			}
	
			.item {
				position: relative;
				opacity: 0.5;
				z-index: 1;
				user-select: none;

	
				&:hover {
					opacity: 0.95;
				}
			}
	
			&.selected {
				.item {
					opacity: 1;
				}
	
				&::before {
					left: 0;
				}
			}
		}
	}

	.sidebar-action {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		z-index: 50;
		width: 20px;
		height: 50px;
		background-color: #212529;
		border-radius: 0 4px 4px 0;
		box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
			rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
		cursor: pointer;
		color: #666;

		svg {
			width: 40px;
			height: auto;
			color: #fafafa;
		}
	}

	&.sidebar-collapsed {
		.label {
			display: none;
		}

		.sidebar-icon {
			font-size: clamp(1.1rem, 1.25vw + 0.65rem, 1.35rem) !important;
		}

		// width: fit-content;
		width: 80px;
	}

	.toggle-sidebar {
    // border: 1px solid #abababa6;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
		z-index: 100;
    cursor: pointer;

		&:hover {
			box-shadow: rgba(50, 50, 93, 0.50) 0px 6px 12px -2px, rgba(0, 0, 0, 0.6) 0px 3px 7px -3px;
		}
	}
}

.sidebar {
	// min-height: 100vh;
	// min-height: 100%;
	transition: width 0.3s ease-in-out;

	@media (width < 700px) {
		align-items: center !important;
		width: fit-content !important;

		.link {
			width: fit-content !important;
			transition: width 0.3s ease-in-out;
		}

		.label {
			display: none;
			transition: opacity 0.3s ease-in-out;
			opacity: 0;
		}
	}
}

@media (width < 700px) {
	.sidebar .sidebar-icon {
		font-size: clamp(1.1rem, 1.25vw + 0.65rem, 1.35rem) !important;
	}

	.toggle-sidebar {
		display: none;
	}
	
	.sidebar {
		// min-width: fit-content !important;
		width: 13vw !important;
	}

	.sidebar .logo {
    max-width: 9vw;
	}
}
