@import '../base/variables';

.page {
	// position: relative;
	// display: flex;
	// flex-direction: column;
	// align-items: stretch;
	// justify-content: center;
	min-height: 100vh;
	// background: linear-gradient(180deg, #f9f5ea 5%, #ffffff 100%);
	// font-family: 'Roboto', sans-serif;
	// background-color: #FAFAFA;
	background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.main {
	position: relative;
	width: 100%;
}

.header-nav {
	position: relative;
	z-index: 100;

	.logo {
		height: 100%;
		height: 50px;
		width: auto;
	}

	.nav {
		position: relative;
		z-index: 50;
		width: fit-content;
		align-self: end;

		a {
			transition: all 0.3s ease-in-out;

			svg {
				filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
				transition: all 0.3s ease-in-out;
			}

			&:hover {
				text-shadow: 3px 3px 4px rgba(30, 30, 30, 0.6);

				svg {
					filter: drop-shadow(3px 3px 4px rgba(30, 30, 30, 0.6));
				}
			}
		}
	}

	// PAGE HERO
	.page-hero {
		// position: absolute;
		// top: 0;
		// width: 100%;
		// height: 120px;
		// padding: 0 clamp(15px, 10vw, 30px);
		background: lightblue;

		.logo-container {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			z-index: 1000;
		}
		
		.content {
			// position: relative;
			// bottom: 20px;


			p {
				letter-spacing: 1px;
				opacity: 0.95;
			}
		}

		.background-video-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			z-index: 0;
			user-select: none;

			.background-video {
				width: 100%;
				height: 100%;
				object-fit: cover;
				user-select: none;
			}
		}
	}
}

// PAGE MAIN CONTENT
.page-main {
	padding: clamp(30px, 5vw, 50px) clamp(15px, 2vw, 50px);
	padding-bottom: clamp(60px, 12vw, 180px);
}

// UTILITIES
.bg-glass {
	background-color: rgba(255, 255, 255, 0.5);
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	backdrop-filter: blur(10px) saturate(1.5);
}

.svg-responsive {
	width: 100%;
	height: 100%;
}

.dashboard-page {
	// PICKS
	.card {
		border: none;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
		border-radius: 6px;

		.card-header {
			border-bottom: none;
			background-color: #f7f7f7;
			background-image: var(--bs-gradient) !important;
			cursor: default !important;

		}
		.team-name {
			&:hover {
				// color: var(--bs-primary) !important;
				color: #0161efe0 !important;
			}
		}

		.card-footer {
			background-color: #f7f7f7;
			background-image: var(--bs-gradient) !important;

			.event-links button:hover {
				// color: var(--bs-dark) !important;
				color: #0161efe0 !important;
			}
		}
		
		.pick-category {
			border-radius: 0.375rem;
			color: var(--bs-dark);
			
			&.covers, &.pickswise, &.dunkelIndex, &.oddstrader {
				.pick {
					.pick-value {
						&.saved {
							// border: 1px solid #198754e3;
							border: 1px solid rgb(25 135 84 / 80%);
							color: #198754e3;
							font-weight: 700;
							// box-shadow: rgba(30, 30, 60, 0.25) 0px 0.67px 1.67px -0.33px, rgba(0, 0, 0, 0.5) 0px 0.33px 1px -0.33px;
							// box-shadow: rgb(25 135 84 / 53%) 1px 1px 2px 1px, rgb(25 135 84 / 47%) -1px 1px 2px -0.5px;
							// box-shadow: rgba(25, 135, 84, 0.35) 1px 2px 4px -0.5px, rgba(25, 135, 84, 0.6) 2px 1.5px 3px -0.5px;
							box-shadow: rgba(25, 135, 84, 0.5) 0px 1px 1px 1px, rgba(25, 135, 84, 0.6) 1px 2px 3px -1px;
						}
					}
				}
			}

			.content-source {
				display: flex;
				justify-content: center;
				padding: 3px 4px;
				opacity: 1;
				border-radius: 0.25rem;
				background-color: #212529cc;
				background-image: var(--bs-gradient)!important;
				width: fit-content;
				margin-left: 0.5rem;
				
				img {
					width: auto;
					height: auto;
					min-height: 0rem;
					max-width: 4.5rem;
				}
			}
		
			.pick-container {
				display: flex;
				flex-direction: column;
				row-gap: 0.35rem;
				padding: 0.50rem;
				padding-top: 0.25rem;
				
				div {
					width: fit-content;
					row-gap: 0.35rem;
		
		
					.pick {
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						column-gap: 0.40rem;
						row-gap: 0.10rem;
						font-size: 0.875rem;
						font-weight: 500;
						color: var(--bs-body-color-secondary);
						line-height: normal;
						width: fit-content;

						.text-justify {
							text-align: justify;
						}
		
						&-label {
							font-weight: 400;
							letter-spacing: -0.5px;
							color: var(--bs-gray-600);
							text-transform: uppercase;
							line-height: normal;
						}
		
						&-value {
							font-weight: 600;
							border: 1px solid var(--bs-gray-400);
							background-color: var(--bs-tertiary-bg);
							background-image: var(--bs-gradient) !important;
							color: var(--bs-gray-600);
							border-radius: 0.25rem;
							display: inline-block;
							padding: 0px 3px;
							width: fit-content;
							line-height: normal;
							text-shadow: rgba(255,255,255,0.5) 0px 3px 3px;
							letter-spacing: -0.4px;
							box-shadow: rgba(30, 30, 60, 0.25) 0px 0.67px 1.67px -0.33px, rgba(0, 0, 0, 0.5) 0px 0.33px 1px -0.33px;

							&:hover {
								border: 1px solid rgb(25 135 84 / 80%);
								color: #198754e3;
								font-weight: 700;
								box-shadow: rgba(25, 135, 84, 0.5) 0px 1px 1px 1px, rgba(25, 135, 84, 0.6) 1px 2px 3px -1px;
							}

							&.has-source-url {
								cursor: pointer;
								&:hover {
									// box-shadow: rgba(25, 135, 84, 0.5) 0px 1px 1px 1px, rgba(25, 135, 84, 0.6) 1px 2px 3px -1px;
									// box-shadow: rgba(25, 135, 84, 0.5) 0px 1px 1px 1px, rgba(25, 135, 84, 0.6) 1px 2px 3px -1px;
									// box-shadow: rgba(25, 135, 84, 0.35) 1px 2px 4px -0.5px, rgba(25, 135, 84, 0.6) 2px 1.5px 3px -0.5px;
									&.saved {
									// box-shadow: rgba(25, 135, 84, 0.35) 1px 2px 4px -0.5px, rgba(25, 135, 84, 0.6) 2px 1.5px 3px -0.5px;
								}
								}
							}
						}
					}	
				}
			}
		}
	}
}

.popover {
	.popover-arrow { 
		&::before,
		&::after {
			border-right-color: black !important;
			border-left-color: black !important;
			// left: 2px;
		}

		// &::after {
		// }
	}
}


.pick-confidence {

	.pick-confidence-container {

		.pickswise-confidence {
			// width: 30px;
			// height: 10px;
		}
	}

}