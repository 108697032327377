.test {
	border: 1px dotted pink;
}

.card--1 {
	background: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.card--2 {
	box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(190, 190, 190), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.card--3 {
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.card-scroll-shadow {
	width: 100%;
	height: 100%;
	padding: 0 20px;
	overflow-y: scroll;
	background: linear-gradient(#f7f7f7 33%, hsla(0, 0%, 96.9%, 0)), linear-gradient(hsla(0, 0%, 96.9%, 0), #f7f7f7 66%) 0 100%,
		radial-gradient(farthest-side at 50% 0, rgba(59, 59, 59, 0.5), transparent),
		radial-gradient(farthest-side at 50% 100%, rgba(59, 59, 59, 0.5), transparent) 0 100%;
	background-color: #f7f7f7;
	background-repeat: no-repeat;
	background-attachment: local, local, scroll, scroll;
	background-size: 100% 45px, 100% 45px, 100% 15px, 100% 15px;
}

.lh-0 {
	line-height: 0 !important;
}

.lh-normal {
	line-height: normal !important;
}

.lh-1 {
	line-height: 1 !important;
}

.hr--gradient {
	/* width: 90%; */
	/* height: 2px; */
	opacity: 0.2;
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		currentColor 30%,
		currentColor 70%,
		rgba(255, 255, 255, 0) 100%
	);
	border: none;
}

.bg-dark-gradient-animate {
	background: #121212;
	background: linear-gradient(135deg, #121212 25%, #1a1a1a 25%, #1a1a1a 50%, #121212 50%, #121212 75%, #1a1a1a 75%, #1a1a1a);
	background-size: 40px 40px;
	animation: bg-move 4s linear infinite;
}

@keyframes bg-move {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 40px 40px;
	}
}

.bg-light-gradient-animate {
	background: #f7f7f7;
	background: linear-gradient(135deg, #f7f7f7 25%, #f1f1f1 25%, #f1f1f1 50%, #f7f7f7 50%, #f7f7f7 75%, #f1f1f1 75%, #f1f1f1);
	background-size: 40px 40px;
	animation: bg-move 8s linear infinite;
}

@keyframes bg-move {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 40px 40px;
	}
}

.ml-auto {
	margin-left: auto !important;
}

/* STYLE FOR PLACEHOLDER TEXT */
input::placeholder,
textarea::placeholder {
	opacity: 0.3 !important;
}

.notes {
	.notes-input:focus {
		outline: none;
		box-shadow: rgba(108, 117, 125, 0.05) 0px 0px 5px 3px !important;
	}
}
