// DATA TABLE
.data-table {
  border-radius: 0.375rem;
  overflow: hidden;

  &.table-striped {
    --bs-table-striped-bg: #f0f3f8;
    // --bs-table-striped-bg: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  }

  // .table-striped > tbody > tr:nth-of-type(odd) {
  //   background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%) !important;
  //   background-size: cover !important;
  // }
  

  .data-table-header {
    display: table-header-group;
    // background: #f0f3f8;

    tr {
      th {
        // background: #ced8e8;
        font-weight: 600;
        color: #444;
      }
    }

    @media (max-width: 850px) {
      display: none;
    }
  }

  .team-name.link {
    &:hover {
      color: var(--bs-primary) !important;
      span {
        color: var(--bs-primary) !important;
      }
    }

  }

  .data-table-body {
    .data-table-row {
      display: table-row;

      // &::after {
      //   content: "";
      //   display: block;
      //   width: 100%;
      //   margin-top: 20px;
      //   padding-bottom: 2px;
      //   border-radius: 0px !important;
      //   height: 2px !important;
      //   opacity: 80%;
      //   background: linear-gradient(
      //     to right,
      //     var(--bs-gray-400),
      //     var(--bs-gray-600),
      //     var(--bs-gray-700)
      //   );
      // }

      @media (max-width: 850px) {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        margin-bottom: 20px !important;
        border-radius: 8px !important;
        overflow: hidden;
        background-color: white;
        padding: 1rem;
      }

      .data-table-cell {
        position: relative;
        display: table-cell;
        align-items: flex-start;
        padding: 8px;
        font-size: 0.875rem;
        vertical-align: middle;
        
        @media (max-width: 850px) {
          display: flex;
          flex-direction: column;
          text-align: left;
          padding: 0px;
          width: 100%;
          // --bs-table-bg-type: transparent;
          // background-color: transparent;

          --bs-table-bg-type: white;
          background-color: white;

          div {
            width: 100%;
          }

          // &.teams {
          //   width: 100%;
          //   margin: 20px 0;

          //   div {
          //     max-width: unset;
          //   }
          // }

          &.favorite {
            // width: 100%;
            button {
              margin: unset;
            }
          }

          &.date {
            .event-venue-image {
              width: 100% !important;
              height: 200px !important;
              background-size: cover !important;
            }
          }
        }
      }
    }
  }
}

.team-container {
  .team-name, .team-record, .team-standing-summary {
    width: fit-content !important;
  }
}

.data-table-cell {
  // width: fit-content;
  // white-space: nowrap;
}

.my-table {
  table-layout: auto;
  width: 100%;
}

.my-table th,
.my-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-table-wrapper {
  overflow-x: auto;
}

// MATCHUP COLUMN
// .teams {
//   max-width: 300px;

//   .matchup-column {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     width: 100%;
//   }
// }

.data-table-header {
  & .favorite {
    text-align: center;
  }
}

// DATATABLE - BODY - ROW - CELL
.data-table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: fit-content !important;
  // FAVORITE CELL
  &.favorite {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    min-width: 40px;
    // padding: auto 20px !important;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      padding: 0;
    }
  }
  &.details {
    // .badge {
    //   filter: opacity(0.9);
    //   --bs-badge-border-radius: 0px !important;
    // }
  }
  // TEAMS CELL
  &.teams {
    .matchup-column {
      // max-width: 500px;
      margin: 0 auto;

      .team-container.away {

      }
      .team-container {

        .team-name {
          color: var(--bs-gray-900);
          text-transform: uppercase;
        }
      
        .team-location {
          color: var(--bs-gray-600);
          text-transform: uppercase;
        }

        &.home {
          align-items: flex-end;
        }

        .team-record {
          line-height: normal;
          color: var(--bs-gray-500);
          font-weight: 600;

          .team-record-label {
            line-height: normal;
            color: var(--bs-gray-500);
            font-weight: 500;
          }
        }
      }
    }
  }
}

.index {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  color: var(--bs-gray-400);
  font-size: 0.80rem;
  font-weight: 300;
  line-height: 1;
  opacity: 0.25;
}

.dynamic-btn-color {
  &:hover {
    filter: brightness(1.05) saturate(1.05);
  }
}

.pick-value-container {
  width: 100%;
  
  .pick {
    display: flex;
    align-items: center;
    min-width: 100px;
    width: fit-content;
    height: 34px;
    max-height: 34px;
    padding: 0 5px;
    border-radius: 6px;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    // border: 1px solid var(--bs-gray-200);

    // background: #f7f7f7;
    // background: linear-gradient(135deg, #f7f7f7 25%, #f1f1f1 25%, #f1f1f1 50%, #f7f7f7 50%, #f7f7f7 75%, #f1f1f1 75%, #f1f1f1);
    background: #f7f7f7;
    // background: linear-gradient(135deg, #e8edf475 25%, #f3f6f996 25%, #f3f6f996 50%, #e8edf475 50%, #e8edf475 75%, #f3f6f996 75%, #f3f6f996);
    background: linear-gradient(135deg, #E9E9E9CC 25%, #f3f6f996 25%, #f3f6f996 50%, #E9E9E9CC 50%, #E9E9E9CC 75%, #f3f6f996 75%, #f3f6f996);
    background-size: 20px 20px;
    animation: bg-move 8s linear infinite;

    &.pick-of-day {
      box-shadow: rgb(23, 192, 220, 0.20) 0px 1px 3px 3px, rgb(23, 192, 220, 0.40) 0px 1px 2px !important;
      border: 1px solid rgb(23, 192, 220, 0.40);
    }

    .pick-logo {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .pick-value {
      margin: 0 auto;
    }
  }
}

// EVENT DETAILS
.details {
  .details-header {
    width: fit-content;
    margin: 0 auto;
  }

  .details-boxscore {
    .away-team {
      .away-team-rank {
        top: -0.30rem;
        left: -0.30rem;
      }

      .home-team-rank {
        top: -0.30rem;
        right: -0.30rem;
      }
    }
  }
}

// EVENT MATCHUP KENPOM
.kenpom {
  display: flex;
  flex-direction: column;
  row-gap: 0.15rem;

  > .kenpom-row:nth-of-type(odd) {
    background-color: #f9f9f999;
    border-radius: 6px;
  }
  // > .kenpom-row:nth-of-type(even) {
  //   background-color: #f5f5f5;
  //   border-radius: 4px;
  // }

  .kenpom-row {
    // &.home-team {
    //   align-items: flex-end;
    // }
    display: flex;
    align-items: center;
    // gap: 0.25rem;
    font-size: 0.70rem;
    font-weight: 400;
    color: var(--bs-gray-500);
    line-height: normal;
    padding: 1px 0;
    
    .kenpom-label {
      display: flex;
      align-items: baseline;
      // justify-content: center;
      gap: 0.25rem;
      font-size: 0.70rem;
      font-weight: 400;
      color: var(--bs-gray-500);
      line-height: normal;
    }
  
    .kenpom-metric {
      position: relative;
      display: flex;
      align-items: baseline;
      gap: 3px;
  
      &.home-team {
        justify-content: flex-end;
      }
  
      
      .kenpom-value {
        display: flex;
        justify-content: center;
        font-size: 0.70rem;
        font-weight: 500;
        color: var(--bs-gray-700);
        line-height: normal;
        min-width: 40px;
  
        &.has-advantage { 
          padding: 1px 3px;
          border-radius: 3px;
          background-color: #FFF;
          font-weight: 600;
          // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px 1px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
          // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        }
      }
  
      .kenpom-value-rank {
        position: absolute;
        bottom: 0;
        
        font-size: 0.55rem;
        // font-weight: 500;
        // color: var(--bs-gray-600);
        color: var(--text-body-tertiary);
  
        line-height: normal;
        // fs-xxxs text-body-tertiary
        
        &.away {
          right: -20px;
        }
        
        &.home {
          left: -20px;
        }
      }
    }
  }
}





.notes-modal {
  .modal-content {
    background-color: #F5EE88;
  }
}