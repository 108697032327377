// SPORTS BETTING PAGE
.sports-betting-page {
  // 

  .scrapped-data-container {
    // 

    .card-content {
      .team-image {
        width: auto;
        height: 3rem;
      }
      
      .content-source {
        top: 0;
        right: 0;
        z-index: 10;
        width: 6rem;
        height: auto;
        user-select: none;
        opacity: 0.85;
        border-radius: 0;
        border-top-right-radius: 0.375rem;
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0;
        filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.3));
        box-shadow: inset 0 -2em 2em rgba(0, 0, 0, 0.1), 0 0 0 1px rgb(190, 190, 190), 0.2em 0.2em 1em rgba(0, 0, 0, 0.3);
      }

      .badge {
        white-space: normal !important;
        line-height: normal !important;
      }
    }
  }
}

.data-actions-container {
    position: sticky;
    top: 10px;
    z-index: 100;
    background: #FAFAFA;
    padding: 20px;
    border-radius: var(--bs-border-radius)!important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}