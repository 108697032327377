// NavBar
// .navbar {
//   .link {
//     opacity: 0.7;
//     transition: all 0.3s ease;

//     &:hover {
//       opacity: 1;
//     }

//     .item {
//       svg {
//         width: auto;
//         height: 30px !important;
//       }
//     }

//     &.active {
//       color: var(--bs-warning) !important;
//       opacity: 1;
//       background: linear-gradient(90deg, #ffc107, #ffeb3b);
//       -webkit-background-clip: text;
//       -webkit-text-fill-color: transparent;

//       filter: drop-shadow(1px -0.5px 1.5px rgba(255, 193, 7, 0.1))
//               drop-shadow(2px -1px 2.5px rgba(255, 193, 7, 0.2));

//       .label {
//         font-weight: 700 !important;
//       }
//     }

//     @media (max-width: 700px) {
//       .item {
//         svg {
//           width: auto;
//           height: 35px !important;
//         }

//         .label {
//           display: none;
//         }
//       }
//     }
//   }
// }

.floating-navbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  border-radius: 300px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border: 2px solid #595959;
  transition: all 0.3s ease;

  a.active {
    color: #FDCD1D !important;
  }

  .navbar-item-icon {
    font-size: 30px
  }

  // .navbar-item-wrapper {

  //   .navbar-item-icon {
  //     // 
  //   }

  //   .navbar-item-label {
  //     // 
  //   }
  // }
}